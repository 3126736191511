import { Typography, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import Mailchimp from '../Mailchimp/New'
import Link from 'next/link'
import { urls } from '../../utils/config'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '50px auto auto auto',
    bottom: '0',
    padding: '0 50px',
    '@media (max-width: 1024px)': {
      padding: '0 10px',
    },
  },
  topFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 420px)': {
      justifyContent: 'center',
    },
  },
  intro: {
    fontSize: '12px',
    fontFamily: 'Inter',
    '@media (max-width: 420px)': {},
    color: '#787878',
    marginBottom: '5px',
  },
  subIntro: {
    fontSize: '12px',
    fontFamily: 'Inter',
    '@media (max-width: 420px)': {},
    color: '#000',
  },
  bottomFooter: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '30px 0',
    alignItems: 'center',
    marginTop: '50px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  social: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    zIndex: 100,
    marginRight: '20px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '50px',
    },
  },
  icon: {
    marginRight: '10px',
  },
  logo: {
    height: '48px',
    width: 'auto',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  border: {
    position: 'absolute',
    width: '98%',
    left: 0,
    height: '330px',
    [theme.breakpoints.down('xs')]: {},
  },
  black: {
    color: theme.palette.text.secondary,
  },
  subFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: '20px 0',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
    },
  },
  mainLink: {
    color: '#000',
    fontSize: '14px',
    fontWeight: 'bold',
    textTransform: 'none',
    margin: '3px 0',
    fontFamily: 'Inter',
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
  },
  subLink: {
    color: '#000',
    fontSize: '14px',
    textTransform: 'none',
    margin: '3px 0',
    fontFamily: 'Inter',
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: '150px',
    [theme.breakpoints.down('md')]: {
      width: '40%',
      margin: '20px 0',
    },
  },
  introContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: '10px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  socialLink: {
    textDecoration: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  desktopOnly: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  mobileOnly: {
    width: '100%',
    display: 'none',
    marginBottom: '20px',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  mobileSocialLinks: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2rem',
  },
  mobileIcon: {},
  mobileBottomFooter: {
    marginTop: '35px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mobileLogo: {
    marginBottom: '15px',
  },
  mobileIntroContainer: {
    textAlign: 'center',
  },
}))

export default function Footer(): JSX.Element {
  const classes = useStyles({})
  return (
    <div className={classes.root}>
      <div className={classes.topFooter}>
        <Link href={urls.home}>
          <img
            width={125}
            height={50}
            src={`/ct-logo-squared.svg`}
            className={classes.logo}
            alt="Cryptotesters Logo"
          />
        </Link>
        <div className={classes.introContainer}>
          <Typography className={classes.intro}>
            We are a multi-faceted team of crypto enthusiasts based in Berlin.
          </Typography>
          <Typography className={classes.subIntro}>© 2021 cryptotesters UG</Typography>
        </div>
        <Mailchimp />
      </div>
      <Divider />
      <div>
        <div className={classes.subFooter}>
          <div className={classes.linkContainer}>
            <Link href={urls.exchanges}>
              <Typography className={classes.mainLink}>Products</Typography>
            </Link>
            <Link href={urls.exchanges}>
              <Typography className={classes.subLink}>Cryptocurrency exchanges</Typography>
            </Link>
            <Link href={urls.howToChooseCryptoWallet}>
              <Typography className={classes.subLink}>Crypto wallet guide</Typography>
            </Link>
            <Link href={urls.savingAccounts}>
              <Typography className={classes.subLink}>Crypto savings accounts</Typography>
            </Link>
            <Link href={urls.defi}>
              <Typography className={classes.subLink}>Defi lending rates</Typography>
            </Link>
            <Link href={urls.cryptoCards}>
              <Typography className={classes.subLink}>Crypto cards</Typography>
            </Link>
            <Link href={urls.deals}>
              <Typography className={classes.subLink}>Exclusive crypto deals</Typography>
            </Link>
            <Link href={urls.ethereumStaking}>
              <Typography className={classes.subLink}>Ethereum staking</Typography>
            </Link>
          </div>
          <div className={classes.linkContainer}>
            <Link href={urls.blog}>
              <Typography className={classes.mainLink}>Resources</Typography>
            </Link>
            <Link href={urls.blog}>
              <Typography className={classes.subLink}>Articles</Typography>
            </Link>
            <Link href={urls.review}>
              <Typography className={classes.subLink}>Reviews</Typography>
            </Link>
            <Link href={urls.podcast}>
              <Typography className={classes.subLink}>Podcasts</Typography>
            </Link>
            <Link href={urls.tutorial}>
              <Typography className={classes.subLink}>Tutorials</Typography>
            </Link>
          </div>
          <div className={`${classes.linkContainer} ${classes.desktopOnly}`}>
            <Link href={urls.about}>
              <Typography className={classes.mainLink}>About us</Typography>
            </Link>
            <a
              href="https://twitter.com/cryptotesters"
              target="_blank"
              rel="noopener noreferrer"
              title="Twitter"
              className={classes.socialLink}
            >
              <img
                width={12}
                height={12}
                className={classes.icon}
                src={`/svg/twitter.svg`}
                alt="Twitter logo"
              />
              <Typography className={classes.subLink}>Twitter</Typography>
            </a>
            <a
              href="https://www.linkedin.com/company/cryptotesters/"
              target="_blank"
              rel="noopener noreferrer"
              title="LinkedIn"
              className={classes.socialLink}
            >
              <img
                width={12}
                height={12}
                className={classes.icon}
                src={`/svg/linkedin.svg`}
                alt="LinkedIn logo"
              />
              <Typography className={classes.subLink}>LinkedIn</Typography>
            </a>
            <a
              href="https://facebook.com/cryptotesters/"
              target="_blank"
              rel="noopener noreferrer"
              title="Facebook"
              className={classes.socialLink}
            >
              <img
                width={12}
                height={12}
                className={classes.icon}
                src={`/svg/facebook.svg`}
                alt="Facebook logo"
              />
              <Typography className={classes.subLink}>Facebook</Typography>
            </a>
          </div>
        </div>
        <div className={classes.mobileOnly}>
          <Divider />
          <div className={classes.mobileSocialLinks}>
            <a
              href="https://twitter.com/cryptotesters"
              target="_blank"
              rel="noopener noreferrer"
              title="Twitter"
              className={classes.socialLink}
            >
              <img
                width={20}
                height={20}
                className={classes.mobileIcon}
                src={`/svg/twitter.svg`}
                alt="Twitter logo"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/cryptotesters/"
              target="_blank"
              rel="noopener noreferrer"
              title="LinkedIn"
              className={classes.socialLink}
            >
              <img
                width={20}
                height={20}
                className={classes.mobileIcon}
                src={`/svg/linkedin.svg`}
                alt="LinkedIn logo"
              />
            </a>
            <a
              href="https://facebook.com/cryptotesters/"
              target="_blank"
              rel="noopener noreferrer"
              title="Facebook"
              className={classes.socialLink}
            >
              <img
                width={20}
                height={20}
                className={classes.mobileIcon}
                src={`/svg/facebook.svg`}
                alt="Facebook logo"
              />
            </a>
          </div>
          <div className={classes.mobileBottomFooter}>
            <Link href={urls.home}>
              <img
                width={125}
                height={50}
                src={`/ct-logo-squared.svg`}
                className={classes.mobileLogo}
                alt="Cryptotesters Logo"
              />
            </Link>
            <div className={classes.mobileIntroContainer}>
              <Typography className={classes.intro}>
                We are a multi-faceted team of crypto enthusiasts based in Berlin.
              </Typography>
              <Typography className={classes.subIntro}>© 2021 cryptotesters UG</Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
