import {
  AppBar,
  Button,
  Popover,
  Toolbar,
  Typography,
  Drawer,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import { useRouter } from 'next/router'
import React from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import NextLink from 'next/link'

import { urls } from '../../utils/config'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: '20px auto',
    padding: '0 30px',
    transition: 'all .3s ease-in-out',
    '&.with-banner': {
      margin: '120px auto 20px',
      transition: 'all .3s ease-in-out',
      [theme.breakpoints.down('sm')]: {
        margin: '160px auto 20px',
      },
    },
  },
  bar: {
    background: 'transparent',
    boxShadow: 'none',
  },
  toolbar: {
    background: 'transparent',
    padding: 0,
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '10px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
      marginBottom: '0px',
      minHeight: 'unset',
    },
  },
  logo: {
    maxHeight: '30px',
    maxWidth: '190px',
    width: 'auto',
    display: 'block',
    '&:hover': {
      cursor: 'pointer',
    },
    '@media (max-width: 420px)': {
      maxHeight: '45px',
    },
    '&.mobile': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
      '&.mobile': {
        display: 'block',
      },
    },
  },
  links: {},
  navTitle: {
    fontFamily: 'Inter',
    textTransform: 'none',
    fontWeight: 600,
    color: 'white',
    fontSize: '16px',
    letterSpacing: '0',
  },
  navButton: {
    border: `1px solid transparent`,
    padding: '15px',
    marginRight: '10px',
    '&:hover p': {
      opacity: 0.6,
    },
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  sectionDesktop: {
    display: 'none',
    marginLeft: '-200px',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  sectionMobile: {
    display: 'flex',
    margin: '0',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  fullList: {
    width: 'auto',
  },
  blogLinks: {
    display: 'flex',
    flexDirection: 'row',
    '& h6': {
      color: theme.palette.primary.main,
      marginRight: '50px',
      [theme.breakpoints.down('md')]: {
        marginRight: '10px',
      },
      '&:hover': {
        cursor: 'pointer',
        opacity: 0.8,
      },
    },
  },
  menu: {
    padding: '20px 30px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: '12px',
  },
  subMenu: {
    fontFamily: 'Inter',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '250px',
  },
  subMenuTitle: {
    fontFamily: 'Inter',
    color: '#2F1465',
    fontSize: '14px',
    fontWeight: 500,
  },
  subMenuSubtitle: {
    fontFamily: 'Inter',
    color: '#A6A6A6',
    fontSize: '14px',
    fontWeight: 500,
  },
  subMenuHeader: {
    marginBottom: '5px',
  },
  menuButton: {
    textTransform: 'none',
    textDecoration: 'none',
    margin: '4px 0',
  },
  menuTitle: {
    fontFamily: 'Inter',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    paddingLeft: '0',
    color: '#000',
    fontSize: '14px',
    fontWeight: 500,
    '&:hover': {
      opacity: 0.8,
    },
  },
  popover: {
    marginTop: '20px',
    pointerEvents: 'none',
    width: '100%',
  },
  paper: {
    pointerEvents: 'auto',
    overflowX: 'visible',
    overflowY: 'visible',
    borderRadius: '12px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.secondary.main,
  },
  panel: {
    marginTop: '5px',
    backgroundColor: 'transparent',
    border: 'none',
    '&:before': {
      height: 0,
    },
  },
  list: {
    padding: '20px',
    width: 250,
  },
  mobileMenu: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  mobileMenuButton: {
    padding: '16px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  panelLogo: {
    marginBottom: '20px',
    maxHeight: '30px',
    width: 'auto',
  },
  accordion: {
    backgroundColor: 'transparent',
  },
  drawerMenuTitle: {
    color: '#000',
    fontSize: '17px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    textTransform: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  drawerMenuSubtitle: {
    margin: '5px 0 5px 25px',
    color: '#000',
    fontFamily: 'Inter',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  expandIcon: {
    color: '#000',
  },
  dropdown: {
    padding: 0,
  },
  drawerRoot: {},
  icon: {
    color: 'white',
    marginTop: '-.6rem',
  },
  black: {
    color: 'black',
  },
  nftBanner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    width: '100%',
    left: 0,
    top: 0,
    zIndex: 1,
    padding: '10px',
    '& a': {
      fontSize: '1rem',
      color: 'white',
      textAlign: 'center',
    },

    '& p': {
      fontSize: '1rem',
      textAlign: 'center',
    },
  },
}))

interface Props {
  dark?: boolean
  variant?: 'light' | 'dark'
}

export default function NavBar({ dark, variant }: Props): JSX.Element {
  const classes = useStyles({})
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState<boolean>(false)
  const { pathname } = useRouter()
  let black = variant === 'dark' || dark || pathname.split('/').length > 2
  black = variant === 'light' ? false : black
  const isMenuOpen = Boolean(true)

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    setMobileMenuOpen(open)
  }

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Link href="/">
        <img
          width={125}
          height={50}
          src={'/ct-logo.svg'}
          alt="Logo Cryptotesters"
          className={classes.panelLogo}
        />
      </Link>
      <Accordion square classes={{ root: classes.panel }} onClick={(e) => e.stopPropagation()}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordion}
        >
          <Typography className={classes.drawerMenuTitle}>Products</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.dropdown} onClick={() => setMobileMenuOpen(false)}>
          <div className={classes.mobileMenu}>
            <Link href={urls.buyBitcoin}>
              <Typography className={classes.drawerMenuSubtitle}>How to buy bitcoin</Typography>
            </Link>
            <Link href={urls.exchanges}>
              <Typography className={classes.drawerMenuSubtitle}>
                Cryptocurrency exchanges
              </Typography>
            </Link>
            <Link href={urls.p2p}>
              <Typography className={classes.drawerMenuSubtitle}>Peer to peer exchanges</Typography>
            </Link>
            <Link href={urls.decentralizedExchanges}>
              <Typography className={classes.drawerMenuSubtitle}>
                Decentralized exchanges
              </Typography>
            </Link>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion square classes={{ root: classes.panel }} onClick={(e) => e.stopPropagation()}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordion}
        >
          <Typography className={classes.drawerMenuTitle}>Wallets</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.dropdown} onClick={() => setMobileMenuOpen(false)}>
          <div className={classes.mobileMenu}>
            <Link href={urls.custodialWallets}>
              <Typography className={classes.drawerMenuSubtitle}>Custodial Wallets</Typography>
            </Link>
            <Link href={urls.nonCustodialWallets}>
              <Typography className={classes.drawerMenuSubtitle}>Non-custodial wallets</Typography>
            </Link>
            <Link href={urls.ethereumWallets}>
              <Typography className={classes.drawerMenuSubtitle}>Ethereum wallets</Typography>
            </Link>
            <Link href={urls.hardwareWallets}>
              <Typography className={classes.drawerMenuSubtitle}>Hardware wallets</Typography>
            </Link>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion square classes={{ root: classes.panel }} onClick={(e) => e.stopPropagation()}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordion}
        >
          <Typography className={classes.drawerMenuTitle}>Academy</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.dropdown} onClick={() => setMobileMenuOpen(false)}>
          <div className={classes.mobileMenu}>
            <Link href={urls.academy}>
              <Typography className={classes.drawerMenuSubtitle}>Academy</Typography>
            </Link>
            <Link href={urls.courses}>
              <Typography className={classes.drawerMenuSubtitle}>Courses</Typography>
            </Link>
            <Link href={urls.lessons}>
              <Typography className={classes.drawerMenuSubtitle}>Lessons</Typography>
            </Link>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion square classes={{ root: classes.panel }} onClick={(e) => e.stopPropagation()}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordion}
        >
          <Typography className={classes.drawerMenuTitle}>Hub</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.dropdown} onClick={() => setMobileMenuOpen(false)}>
          <div className={classes.mobileMenu}>
            <Link href={urls.hub}>
              <Typography className={classes.drawerMenuSubtitle}>Hub</Typography>
            </Link>
            <Link href={urls.blog}>
              <Typography className={classes.drawerMenuSubtitle}>Articles</Typography>
            </Link>
            <Link href={urls.community}>
              <Typography className={classes.drawerMenuSubtitle}>Community</Typography>
            </Link>
            <Link href={urls.podcast}>
              <Typography className={classes.drawerMenuSubtitle}>Podcasts</Typography>
            </Link>
            <Link href={urls.review}>
              <Typography className={classes.drawerMenuSubtitle}>Reviews</Typography>
            </Link>
            <Link href={urls.tutorial}>
              <Typography className={classes.drawerMenuSubtitle}>Tutorials</Typography>
            </Link>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )

  const logoUrl = () => {
    if (black) {
      return '/ct-logo.svg'
    }
    return '/ct-logo-white.svg'
  }

  return (
    <div className={`${classes.root} ${!pathname.startsWith(urls.community) ? 'with-banner' : ''}`}>
      {!pathname.startsWith(urls.community) ? (
        <div className={classes.nftBanner}>
          <NextLink href={urls.mint}>
            <>
              <p>
                🎉🧪 You can now become an official Cryptotester member by minting the new Tester
                Membership NFTs. 🥳🎊 <br />
                <Link href={urls.mint} underline="always">
                  Click here for details ⇗
                </Link>
              </p>
            </>
          </NextLink>
        </div>
      ) : null}
      <AppBar position="static" className={classes.bar}>
        <Toolbar className={classes.toolbar}>
          <Link href="/">
            <img
              width={125}
              height={50}
              src={logoUrl()}
              alt="Logo Cryptotesters"
              className={classes.logo}
            />
            <img
              width={125}
              height={50}
              src={'/ct-new-logo-squared.svg'}
              alt="Logo Cryptotesters"
              className={`${classes.logo} mobile`}
            />
          </Link>
          <>
            <div className={classes.sectionDesktop}>
              <div>
                <Button
                  className={classes.navButton}
                  onMouseOver={handleOpenMenu}
                  id="0"
                  aria-owns={isMenuOpen ? 'mouse-over-popover' : undefined}
                  aria-haspopup="true"
                >
                  <Typography className={`${classes.navTitle} ${black && classes.black} `}>
                    Products
                  </Typography>
                </Button>
                <Button
                  className={classes.navButton}
                  onMouseOver={handleOpenMenu}
                  id="1"
                  aria-owns={isMenuOpen ? 'mouse-over-popover' : undefined}
                  aria-haspopup="true"
                  href="/hub"
                >
                  <Typography className={`${classes.navTitle} ${black && classes.black} `}>
                    Hub
                  </Typography>
                </Button>
                <Button
                  className={classes.navButton}
                  onMouseOver={handleOpenMenu}
                  id="2"
                  aria-owns={isMenuOpen ? 'mouse-over-popover' : undefined}
                  aria-haspopup="true"
                  href="/academy"
                >
                  <Typography className={`${classes.navTitle} ${black && classes.black} `}>
                    Academy
                  </Typography>
                </Button>
              </div>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls="menu-mobile"
                aria-haspopup="true"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon className={`${classes.icon} ${black && classes.black}`} />
              </IconButton>
              <Drawer
                anchor="left"
                open={mobileMenuOpen}
                onClose={toggleDrawer(false)}
                classes={{ paper: classes.drawerRoot }}
              >
                {list()}
              </Drawer>
            </div>
          </>
          <span />
        </Toolbar>
      </AppBar>
      {anchorEl && (
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={isMenuOpen}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 30,
            horizontal: 'center',
          }}
          onMouseLeave={handleClose}
        >
          {anchorEl?.id === '0' && (
            <div className={classes.menu}>
              <div className={classes.subMenu}>
                <div className={classes.subMenuHeader}>
                  <Typography className={classes.subMenuTitle}>Exchanges</Typography>
                  <Typography className={classes.subMenuSubtitle}>Buy &amp; Sell</Typography>
                </div>
                <Link href={urls.exchanges}>
                  <a className={classes.menuButton}>
                    <Typography className={classes.menuTitle}>Cryptocurrency exchanges</Typography>
                  </a>
                </Link>
                <Link href={urls.p2p}>
                  <a className={classes.menuButton}>
                    <Typography className={classes.menuTitle}>Peer to peer exchanges</Typography>
                  </a>
                </Link>
                <Link href={urls.decentralizedExchanges}>
                  <a className={classes.menuButton}>
                    <Typography className={classes.menuTitle}>Decentralized exchanges</Typography>
                  </a>
                </Link>
              </div>
              <div className={classes.subMenu}>
                <div className={classes.subMenuHeader}>
                  <Typography className={classes.subMenuTitle}>Wallets</Typography>
                  <Typography className={classes.subMenuSubtitle}>Store &amp; Send</Typography>
                </div>
                <Link href={urls.custodialWallets}>
                  <a className={classes.menuButton}>
                    <Typography className={classes.menuTitle}>Custodial wallets</Typography>
                  </a>
                </Link>
                <Link href={urls.nonCustodialWallets}>
                  <a className={classes.menuButton}>
                    <Typography className={classes.menuTitle}>Non Custodial wallets</Typography>
                  </a>
                </Link>
                <Link href={urls.ethereumWallets}>
                  <a className={classes.menuButton}>
                    <Typography className={classes.menuTitle}>Ethereum wallets</Typography>
                  </a>
                </Link>
                <Link href={urls.hardwareWallets}>
                  <a className={classes.menuButton}>
                    <Typography className={classes.menuTitle}>Hardware wallets</Typography>
                  </a>
                </Link>
              </div>
              <div className={classes.subMenu}>
                <div className={classes.subMenuHeader}>
                  <Typography className={classes.subMenuTitle}>Earn</Typography>
                  <Typography className={classes.subMenuSubtitle}>Borrow &amp; Lend</Typography>
                </div>
                <Link href={urls.savingAccounts}>
                  <a className={classes.menuButton}>
                    <Typography className={classes.menuTitle}>Crypto saving accounts</Typography>
                  </a>
                </Link>
                <Link href={urls.ethereumStaking}>
                  <a className={classes.menuButton}>
                    <Typography className={classes.menuTitle}>Ethereum staking</Typography>
                  </a>
                </Link>
                <Link href={urls.defi}>
                  <a className={classes.menuButton}>
                    <Typography className={classes.menuTitle}>Defi lending rates</Typography>
                  </a>
                </Link>
              </div>
              <div className={classes.subMenu}>
                <div className={classes.subMenuHeader}>
                  <Typography className={classes.subMenuTitle}>Rewards</Typography>
                  <Typography className={classes.subMenuSubtitle}>Cards &amp; Deals</Typography>
                </div>
                <Link href={urls.cryptoCards}>
                  <a className={classes.menuButton}>
                    <Typography className={classes.menuTitle}>Crypto cards</Typography>
                  </a>
                </Link>
                <Link href={urls.deals}>
                  <a className={classes.menuButton}>
                    <Typography className={classes.menuTitle}>Exclusive deals</Typography>
                  </a>
                </Link>
              </div>
            </div>
          )}
          {anchorEl?.id === '1' && (
            <div className={classes.menu}>
              <div className={classes.subMenu}>
                <Link href={urls.blog}>
                  <a className={classes.menuButton}>
                    <Typography className={classes.menuTitle}>Articles</Typography>
                  </a>
                </Link>
                <Link href={urls.community}>
                  <a className={classes.menuButton}>
                    <Typography className={classes.menuTitle}>Community</Typography>
                  </a>
                </Link>
                <Link href={urls.podcast}>
                  <a className={classes.menuButton}>
                    <Typography className={classes.menuTitle}>Podcasts</Typography>
                  </a>
                </Link>
                <Link href={urls.review}>
                  <a className={classes.menuButton}>
                    <Typography className={classes.menuTitle}>Reviews</Typography>
                  </a>
                </Link>
                <Link href={urls.tutorial}>
                  <a className={classes.menuButton}>
                    <Typography className={classes.menuTitle}>Tutorials</Typography>
                  </a>
                </Link>
              </div>
            </div>
          )}
          {anchorEl?.id === '2' && (
            <div className={classes.menu}>
              <div className={classes.subMenu}>
                <Link href={urls.courses}>
                  <a className={classes.menuButton}>
                    <Typography className={classes.menuTitle}>Courses</Typography>
                  </a>
                </Link>
                <Link href={urls.lessons}>
                  <a className={classes.menuButton}>
                    <Typography className={classes.menuTitle}>Lessons</Typography>
                  </a>
                </Link>
              </div>
            </div>
          )}
        </Popover>
      )}
    </div>
  )
}

NavBar.defaultProps = {
  dark: false,
}
