export const urls = {
  exchanges: '/best-cryptocurrency-exchanges',
  custodialWallets: '/best-cryptocurrency-wallets',
  nonCustodialWallets: '/best-non-custodial-wallets',
  hardwareWallets: '/best-hardware-wallets',
  physicalWallets: '/best-hardware-wallets',
  blog: '/blog',
  podcast: '/podcast',
  review: '/review',
  tutorial: '/tutorial',
  about: '/about',
  p2p: '/best-p2p-exchanges',
  savingAccounts: '/best-crypto-savings-accounts',
  defi: '/best-defi-lending-rates',
  cryptoCards: '/best-cryptocurrency-cards',
  decentralizedExchanges: '/best-decentralized-exchanges',
  howToChooseCryptoWallet: '/how-to-choose-a-crypto-wallet',
  bitcoinWallets: '/best-bitcoin-wallets',
  ethereumWallets: '/best-ethereum-wallets',
  buyBitcoin: '/buy-bitcoin',
  deals: '/deals',
  ethereumStaking: '/ethereum-staking-platforms',
  tokens: '/tokens',
  token: '/token',
  home: '/',
  etoro: '/etoro-deal',
  notFound: '/404',
  courses: '/courses',
  lessons: '/lesson',
  hub: '/hub',
  academy: '/academy',
  twitter: 'https://twitter.com/cryptotesters',
  telegram: 'https://t.me/joinchat/IOe3w04NMhLuDEo3ekLeCQ',
  community: '/community',
  mint: '/community/mint',
  productExchanges: '/exchanges',
  productWallets: '/wallets',
  productEarn: '/earn',
  productRewards: '/rewards',
}

export const tabs = {
  exchanges: 'exchanges',
  custodialWallets: 'custodialWallets',
  nonCustodialWallets: 'nonCustodialWallets',
  hardwareWallets: 'hardwareWallets',
  blog: 'blog',
  podcast: 'podcast',
  review: 'review',
  about: 'about',
  p2p: 'p2p',
  savingAccounts: 'savingAccounts',
  cryptoCards: 'cryptoCards',
  decentralizedExchanges: 'decentralizedExchanges',
  howToChooseCryptoWallet: 'howToChooseCryptoWallet',
  ethereumWallets: 'ethereumWallets',
  bitcoinWallets: 'bitcoinWallets',
  defi: 'defi',
  buyBitcoin: 'buyBitcoin',
  deals: 'deals',
  ethereumStaking: 'ethereumStaking',
  tokens: 'tokens',
}

export const slideIndex = {
  blog: 0,
  podcast: 1,
  review: 2,
  'best-cryptocurrency-wallets': 0,
  'best-non-custodial-wallets': 1,
  'best-hardware-wallets': 2,
}

let serverURL = ''

switch (process.env.NEXT_PUBLIC_VERCEL_ENV) {
  case 'production':
    serverURL = 'https://cryptotesters.com'
    break
  case 'development':
    serverURL = 'http://localhost:3000'
    break
  default:
    serverURL = `https://${process.env.NEXT_PUBLIC_APP_URL}`
    break
}

export const server = serverURL
