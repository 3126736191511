import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  mailchimp: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '100%',
    '& #mc_embed_signup form': {
      textAlign: 'left',
    },
    '& #text': {
      display: 'flex',
      flexDirection: 'row',
      width: '225px',
      margin: '0 20px',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '0',
        margin: 0,
      },
    },
    '& #submit-form': {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'row',
        // justifyContent: 'space-between',
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        // justifyContent: 'space-between',
      },
    },
    '& button': {
      borderRadius: '8px',
      fontFamily: 'Inter',
      textTransform: 'none',
      height: '40px',
      fontSize: '14px',
      fontWeight: 'bold',
      border: 0,
      width: '128px',
      letterSpacing: '0.5px',
      lineHeight: 1.3,
      boxShadow: 'none',
      backgroundColor: '#000',
      color: '#fff',
      marginLeft: '4px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    '& #mc_embed_signup .email, & #mc_embed_signup .name': {
      fontFamily: 'Inter',
      width: '200px',
      height: '40px',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      color: '#000',
      lineHeight: '100%',
      border: '0',
      borderRadius: '8px',
      marginBottom: 0,
      padding: '0 10px',
      margin: '0 10px',
      '&::placeholder': {
        color: '#000',
        opacity: 0.5,
        fontSize: '14px',
        paddingLeft: '10px',
      },
      [theme.breakpoints.down('md')]: {
        margin: ' 10px ',
      },
    },
    '& #mc_embed_signup #title': {
      fontFamily: 'Inter',
      fontSize: '12px',
      color: '#000',
    },
    '& #mc_embed_signup #subtitle': {
      fontFamily: 'Inter',
      color: '#787878',
      fontSize: '12px',
      fontWeight: 400,
    },
    '& #mc_embed_signup_scroll': {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse',
      },
    },
  },
}))

const html = `
<!-- Begin Mailchimp Signup Form -->
<div id="mc_embed_signup">
  <form action="https://www.getrevue.co/profile/cryptotesters/add_subscriber" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
    <div id="mc_embed_signup_scroll">
      <div id="text">
        <p>
          <span id="title" for="mce-EMAIL">Stay up to date!&nbsp;</span>
          <span id="subtitle">Sign-up for a monthly roundup of the newest crypto products</span>
        </p>
      </div>
      <div id="submit-form">
        <input type="text" value="" name="member[first_name]" class="name" id="member_first_name" placeholder="your name" required>
        <input type="email" value="" name="member[email]" class="email" id="member_email" placeholder="email@example.com" required>
      
          <button type="submit" name="subscribe">Submit</button>
      </div>
    </div>
  </form>
</div>

<!--End mc_embed_signup-->
`

export default function MailChimp(): JSX.Element {
  const classes = useStyles({})

  return <div className={classes.mailchimp} dangerouslySetInnerHTML={{ __html: html }} />
}
